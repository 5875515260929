import { useNavigate } from 'react-router-dom';
import { flushSync } from 'react-dom';

if (typeof document !== 'undefined') {
  // Simple polyfill
  document.startViewTransition ||= function (fn) {
    fn();
  };
}

export default function Link(props) {
  const { to, children, className } = props;
  const navigate = useNavigate();

  function onClick(evt) {
    evt.preventDefault();

    document.startViewTransition(() => {
      flushSync(() => {
        navigate(to);
      });
    });
  }

  return (
    <a href={to} onClick={onClick} className={className}>
      {children}
    </a>
  );
}
