import { Spacer, Icon, ExternalLink } from '@bedrockio/pages';
import { useClass } from '@bedrockio/pages/utils';

import Container from 'components/Container';
import Link from 'components/Link';
import Logo from 'components/Logo';

import jieun from 'assets/images/jieun.avif';
import mimi from 'assets/images/mimi.avif';
import because from 'assets/images/because.svg';

import './about.less';

export default function About() {
  const { className } = useClass('about');
  return (
    <div className={className}>
      <Container>
        <Spacer size="lg" />
        <h1>About</h1>
        <Logo name="about" />
        <Spacer />
        <p className="center">
          Drop Edition is the co-creation of{' '}
          <ExternalLink href="https://www.instagram.com/jieunbillion/">
            Jieun Kim
          </ExternalLink>{' '}
          and{' '}
          <ExternalLink href="https://www.instagram.com/mimikimny/">
            Mimi Kim
          </ExternalLink>
          , two entrepreneurial women with careers in fashion spanning from
          Seoul to Tokyo, to LA and NYC. They seek to inspire professional women
          (and men) with their unique and distinct style, as embodied in their
          limited edition apparel, which balances elegance and simplicity.
        </p>
        <Spacer size="lg" />
        <div className="gap">
          <ExternalLink href="https://www.instagram.com/jieunbillion/">
            <img src={jieun} width="97" height="97" />
          </ExternalLink>
          <ExternalLink href="https://www.instagram.com/mimikimny/">
            <img src={mimi} width="97" height="97" />
          </ExternalLink>
        </div>
        <Spacer size="lg" />
        <p className="center">
          Drop Edition will be contributing a portion of each sale to{' '}
          <ExternalLink href="https://dressforsuccess.org/">
            Dress For Success
          </ExternalLink>
          , a not-for-profit organization that you can read more about here . We
          believe if you look your best, it's a small but important step towards
          becoming your best self.
        </p>

        <Spacer />
        <div className="gap">
          <ExternalLink href="https://www.instagram.com/dropedition_official/">
            <Icon name="instagram" />
          </ExternalLink>
          <ExternalLink href="https://beca.us/e/svaqxt">
            <img src={because} width="120" height="60" />
          </ExternalLink>
          <Link to="/" className="body-color">
            Home
          </Link>
        </div>
      </Container>
    </div>
  );
}
