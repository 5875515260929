import React from 'react';

import { Meta, Spacer } from '@bedrockio/pages';

import Container from 'components/Container';

export default function NotFound() {
  return (
    <React.Fragment>
      <Meta>
        <title>Page Not Found</title>
      </Meta>
      <Container>
        <Spacer size="xl" />
        <h1 className="center">Sorry, that page was not found.</h1>
        <Spacer size="xl" />
      </Container>
    </React.Fragment>
  );
}
