import './logo.less';

import { useClass } from '@bedrockio/pages/utils';

export default function Logo(props) {
  const { name } = props;
  const { className } = useClass('logo');

  return (
    <h2 className={className} style={{ '--name': name }}>
      DROP EDITI<span className="sup">O</span>N
    </h2>
  );
}
