import { Icon, ErrorBoundary } from '@bedrockio/pages';

import ErrorPage from 'pages/Error';

import icons from './assets/icons.svg';

import Router from './Router';

import './app.less';

Icon.useSet(icons);

export default function App() {
  return (
    <main>
      <ErrorBoundary fallback={<ErrorPage />}>
        <Router />
      </ErrorBoundary>
    </main>
  );
}
