import { useState, useEffect } from 'react';
import {
  Spacer,
  Image,
  Icon,
  Form,
  Spinner,
  ExternalLink,
} from '@bedrockio/pages';
import { useClass } from '@bedrockio/pages/utils';

import Container from 'components/Container';
import ScrollFade from 'components/ScrollFade';
import Logo from 'components/Logo';
import Link from 'components/Link';

import hero from 'assets/images/sets/hero';
import because from 'assets/images/because.svg';

import './home.less';

const WEB_APP_URL =
  'https://script.google.com/macros/s/AKfycbzdDthx4J4WErmxBhLfbzLvlnX6hgpjGEf8i1ZGZCpJ2yB3X6uGZPmxJehFasYmOQUb/exec';

const EMAIL_REG = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

function hasSubmitted() {
  if (typeof localStorage === 'undefined') {
    return false;
  }
  return !!localStorage.getItem('submitted');
}

export default function Home() {
  const { className, getElementClass } = useClass('home');

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (hasSubmitted()) {
      setSubmitted(true);
    }
  });

  function onChange({ value }) {
    setEmail(value);
  }

  async function onSubmit() {
    try {
      if (!EMAIL_REG.test(email)) {
        throw new Error('Please enter a valid email address.');
      }

      setLoading(true);

      const url = new URL(WEB_APP_URL);
      url.searchParams.append('email', email);

      await fetch(url, {
        mode: 'no-cors',
      });
      localStorage.setItem('submitted', '1');
      setSubmitted(true);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  return (
    <div className={className}>
      <Container>
        <Spacer />
        <div className={getElementClass('hero')}>
          <Image set={hero} />
          <div className={getElementClass('backdrop')}>
            <Logo name="home" />
          </div>
        </div>
        <Spacer />
        <ScrollFade>
          <p className="center balance spaced contained semibold">
            Enter your email to access our exclusive shop and future Drops
          </p>
        </ScrollFade>
        <Spacer />
        {submitted ? (
          <div className="mirtha center lg">
            <p>You're on the list.</p>
            <p>
              Now click <a href="https://shop.dropedition.com">here</a> to{' '}
              <b>SHOP</b>.
            </p>
          </div>
        ) : loading ? (
          <div className="center">
            <Spinner inline />
          </div>
        ) : (
          <Form onSubmit={onSubmit}>
            <Form.Input
              placeholder="Enter Mail"
              value={email}
              onChange={onChange}
            />
            <Form.Button onClick={onSubmit}>Submit</Form.Button>
            {error && <div className="error">{error.message}</div>}
          </Form>
        )}
        <Spacer />
        <div className="gap">
          <ExternalLink href="https://www.instagram.com/dropedition_official/">
            <Icon name="instagram" />
          </ExternalLink>
          <ExternalLink href="https://beca.us/e/svaqxt">
            <img src={because} width="120" height="60" />
          </ExternalLink>
          <Link to="/about" className="body-color">
            About
          </Link>
        </div>
      </Container>
    </div>
  );
}
