import { useState, useRef, useMemo } from 'react';

import { useClass, onMount, onUnmount, window } from '@bedrockio/pages/utils';

import './scroll-fade.less';

const SUPPORTED = !!window.IntersectionObserver;

export default function ScrollFade(props) {
  const [entered, setEntered] = useState(false);
  const { className } = useClass('scroll-fade', entered ? 'entered' : null);

  const ref = useRef();

  const observer = useMemo(() => {
    if (SUPPORTED) {
      return new IntersectionObserver(onElementObserve, {
        rootMargin: '0px',
        threshold: 0.2,
      });
    }
  }, []);

  // Lifecycle

  onMount(() => {
    observer.observe(ref.current);
  });

  onUnmount(() => {
    observer.disconnect();
  });

  // Observer

  // Events

  function onElementObserve(entries) {
    const [entry] = entries;
    const newEntered = entry.isIntersecting;
    if (newEntered !== entered) {
      setEntered(newEntered);
    }
  }

  return (
    <div ref={ref} className={className} {...props}>
      {props.children}
    </div>
  );
}
